<template>
    <div id="preloader">
        <div id="loader">
            <div class="line-scale-pulse-out">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
		return {
			
		}
    },
    methods: {

    }    
}
</script>
<style>
/* html {
	font-size: 10px;
}

@media only screen and (max-width:400px) {
	html {
		font-size: 9.411764705882353px;
	}
} */

body {
	/* background: #111111;
	font-family: "metropolis-regular", sans-serif;
	font-size: 1.7rem;
	font-style: normal;
	font-weight: normal;
	line-height: 1.765;
	color: #757575;
	margin: 0;
	padding: 0; */
}

#preloader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #050505;
	opacity: 0.8;
	z-index: 800;
	height: 100%;
	width: 100%;
	display: table;
}

.no-js #preloader,
.oldie #preloader {
	display: none;
}

#loader {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

.line-scale-pulse-out>div {
	background-color: #409EFF;
	width: 4px;
	height: 35px;
	border-radius: 2px;
	margin: 2px;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	display: inline-block;
	-webkit-animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
	animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
}

.line-scale-pulse-out>div:nth-child(2),
.line-scale-pulse-out>div:nth-child(4) {
	-webkit-animation-delay: -0.4s !important;
	animation-delay: -0.4s !important;
}

.line-scale-pulse-out>div:nth-child(1),
.line-scale-pulse-out>div:nth-child(5) {
	-webkit-animation-delay: -0.2s !important;
	animation-delay: -0.2s !important;
}

@-webkit-keyframes line-scale-pulse-out {
	0% {
		-webkit-transform: scaley(1);
		transform: scaley(1);
	}

	50% {
		-webkit-transform: scaley(0.4);
		transform: scaley(0.4);
	}

	100% {
		-webkit-transform: scaley(1);
		transform: scaley(1);
	}
}

@keyframes line-scale-pulse-out {
	0% {
		-webkit-transform: scaley(1);
		transform: scaley(1);
	}

	50% {
		-webkit-transform: scaley(0.4);
		transform: scaley(0.4);
	}

	100% {
		-webkit-transform: scaley(1);
		transform: scaley(1);
	}
}
</style>