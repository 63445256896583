<template>
  <div style="padding-top: 10px;">
    <el-table class="tables" :data="tableData" height="81vh">
      <el-table-column prop="tag" label="Tag"></el-table-column>
      <el-table-column prop="describe" label="Tag描述">
        <template slot-scope="scope">
          {{ scope.row.describe?scope.row.describe:'-' }}
        </template>
      </el-table-column>
      <el-table-column prop="tagValue" label="Tag值"></el-table-column>
      <el-table-column prop="staffName" label="操作人"></el-table-column>
      <el-table-column prop="result" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.result == 1" size="small" type="success">成功</el-tag>
          <el-tag v-else-if="scope.row.result == 0" size="small" type="danger">失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="操作时间" width="170">
        <template slot-scope="scope">
          {{scope.row.createTime ? dayjs(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss") : "-"}}
        </template>
      </el-table-column>
    </el-table>
    <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from "dayjs";
export default {
  props: {
    configProEquipments: {
      type: Array
    }
  },
  components: {
    page
  },
  data() {
    return {
      size: 100,
      page: 1,
      total: 0,
      tableData: [],
      roleId: 0
    };
  },
  watch: {
    configProEquipments: {
      handler (newVal, oldVal) {
        this.configProEquipments = newVal
        newVal && newVal.length!=0 && this.loadListData()
      }
    }
  },
  methods: {
    dayjs,
    loadListData() {
      this.$emit('configLoadingBinds',true)
      this.$ajax.post('configOperaRecord', {
        pageNum: this.page,
        pageSize: this.size,
        eids: this.configProEquipments
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.$emit('configLoadingBinds',false)
      }).catch(err=>{
        this.$emit('configLoadingBinds',false)
      })
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadListData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 100
    },
  },
  mounted() {

  }
};
</script>

<style lang="less" scoped>
@media screen and (max-width:1000px) {
  .tables {
    height: calc(100vh - 16vh) !important;
  }
}
</style>